import { SelectOption } from './form';
import { shift } from './shift';
import { user } from './user';
import { UserFlag } from './userFlag';

export enum ActionItemStatus {
  OPEN = 'Open',
  RESOLVED = 'Resolved',
}

export interface actionItem {
  assignedBy?: user; // staff
  assignedTo?: user; // staff
  createdAt?: string; // DateTime
  id?: number;
  priority?: number;
  resolvedAt?: string; // DateTime
  resolvedBy?: user;
  siblingTasks?: actionItem[];
  shift?: shift;
  type?: ActionItemType;
  updatedAt?: string; // DateTime
  userFlag?: UserFlag;
  uuid?: string;
  worker?: user;
}

export enum ActionItemType {
  // Action Board Items
  BACKGROUND_CHECK_ACCEPTED = 'BACKGROUND_CHECK_ACCEPTED',
  BACKGROUND_CHECK_OVERBOOKED = 'BACKGROUND_CHECK_OVERBOOKED',
  BLOCKED_WITH_UPCOMING = 'BLOCKED_WITH_UPCOMING',
  CHECK_IN_BOUNDS = 'CHECK_IN_BOUNDS',
  CHECK_OUT_BOUNDS = 'CHECK_OUT_BOUNDS',
  EARLY_CHECK_OUT = 'EARLY_CHECK_OUT',
  MISSING_CHECK_IN = 'MISSING_CHECK_IN',
  MISSING_CHECK_OUT = 'MISSING_CHECK_OUT',
  MISSING_T2 = 'MISSING_T2',
  MISSING_T24 = 'MISSING_T24',
  OVERBOOKED = 'OVERBOOKED',
  UNAPPROVED_CHECK_IN = 'UNAPPROVED_CHECK_IN',
  UNAPPROVED_CHECK_OUT = 'UNAPPROVED_CHECK_OUT',
  UNFILLED = 'UNFILLED',
  UNFILLED_PLAYBOOK_RUNNING = 'UNFILLED_PLAYBOOK_RUNNING',
  FLAG_MISSED_CONFIRMATIONS = 'FLAG_MISSED_CONFIRMATIONS',
  FLAG_NEGATIVE_REVIEW = 'FLAG_NEGATIVE_REVIEW',
  FLAG_OTHER = 'FLAG_OTHER',
  FLAG_OUTDATED_PAPERWORK = 'FLAG_OUTDATED_PAPERWORK',
  FLAG_PROFILE_PHOTO = 'FLAG_PROFILE_PHOTO',
  FLAG_TERMS_VIOLATION = 'FLAG_TERMS_VIOLATION',
  POST_SHIFT_BACKGROUND_CHECK = 'POST_SHIFT_BACKGROUND_CHECK',
  POST_SHIFT_MISSING_EVEREE_INTEGRATION = 'POST_SHIFT_MISSING_EVEREE_INTEGRATION',
  MISSING_I9_ACCEPTED = 'MISSING_I9_ACCEPTED',
  MISSING_I9_OVERBOOKED = 'MISSING_I9_OVERBOOKED',
  POST_SHIFT_MISSING_I9 = 'POST_SHIFT_MISSING_I9',
  POST_SHIFT_PAPERWORK = 'POST_SHIFT_PAPERWORK',
  PRE_SHIFT_PAPERWORK = 'PRE_SHIFT_PAPERWORK',
}

export const ActionItemDetailedLabels = {
  [ActionItemType.BACKGROUND_CHECK_ACCEPTED]: 'Background Check',
  [ActionItemType.BACKGROUND_CHECK_OVERBOOKED]: 'Background Check OB',
  [ActionItemType.BLOCKED_WITH_UPCOMING]: 'Blocked with Upcoming',
  [ActionItemType.CHECK_IN_BOUNDS]: 'Check-In Bounds',
  [ActionItemType.CHECK_OUT_BOUNDS]: 'Check-Out Bounds',
  [ActionItemType.EARLY_CHECK_OUT]: 'Early Check-Out',
  [ActionItemType.FLAG_MISSED_CONFIRMATIONS]: 'Flag: Missing Confirmations',
  [ActionItemType.FLAG_NEGATIVE_REVIEW]: 'Flag: Negative Review',
  [ActionItemType.FLAG_OTHER]: 'Flag: Other',
  [ActionItemType.FLAG_OUTDATED_PAPERWORK]: 'Flag: Paperwork',
  [ActionItemType.FLAG_PROFILE_PHOTO]: 'Flag: Photo',
  [ActionItemType.FLAG_TERMS_VIOLATION]: 'Flag: Terms Violation',
  [ActionItemType.MISSING_CHECK_IN]: 'Missing Check-In',
  [ActionItemType.MISSING_CHECK_OUT]: 'Missing Check-Out',
  [ActionItemType.MISSING_T2]: 'Missing T2',
  [ActionItemType.MISSING_T24]: 'Missing T24',
  [ActionItemType.OVERBOOKED]: 'Overbooked',
  [ActionItemType.POST_SHIFT_BACKGROUND_CHECK]: 'Background Check',
  [ActionItemType.POST_SHIFT_MISSING_EVEREE_INTEGRATION]: 'Missing Everee',
  [ActionItemType.MISSING_I9_ACCEPTED]: 'Missing I9 Accepted',
  [ActionItemType.MISSING_I9_OVERBOOKED]: 'Missing I9 Overbooked',
  [ActionItemType.POST_SHIFT_MISSING_I9]: 'Missing I9 Post-Shift',
  [ActionItemType.POST_SHIFT_PAPERWORK]: 'Worker Agreement (Post-Shift)',
  [ActionItemType.PRE_SHIFT_PAPERWORK]: 'Worker Agreement (Pre-Shift)',
  [ActionItemType.UNFILLED]: 'Unfilled',
  [ActionItemType.UNFILLED_PLAYBOOK_RUNNING]: 'Playbook Running',
  [ActionItemType.UNAPPROVED_CHECK_IN]: 'Unapproved Check-In',
  [ActionItemType.UNAPPROVED_CHECK_OUT]: 'Unapproved Check-Out',
};

export const ActionItemTree = {
  BACKGROUND_CHECK: {
    label: 'Background Check',
    types: [
      ActionItemType.BACKGROUND_CHECK_ACCEPTED,
      ActionItemType.BACKGROUND_CHECK_OVERBOOKED,
    ],
    board: ['shift'],
  },
  BLOCKED_WITH_UPCOMING: {
    label: 'Blocked with Upcoming',
    types: [ActionItemType.BLOCKED_WITH_UPCOMING],
    board: ['shift', 'worker'],
  },
  CHECK_IN_BOUNDS: {
    label: 'Check-In Bounds',
    types: [ActionItemType.CHECK_IN_BOUNDS],
    board: ['shift'],
  },
  CHECK_OUT_BOUNDS: {
    label: 'Check-Out Bounds',
    types: [ActionItemType.CHECK_OUT_BOUNDS],
    board: ['shift'],
  },
  EARLY_CHECK_OUT: {
    label: 'Early Check-Out',
    types: [ActionItemType.EARLY_CHECK_OUT],
    board: ['shift'],
  },
  MISSING_CHECK_IN: {
    label: 'Missing Check-In',
    types: [ActionItemType.MISSING_CHECK_IN],
    board: ['shift'],
  },
  UNAPPROVED_CHECK_IN: {
    label: 'Unapproved Check-In',
    types: [ActionItemType.UNAPPROVED_CHECK_IN],
    board: ['shift'],
  },
  MISSING_CHECK_OUT: {
    label: 'Missing Check-Out',
    types: [ActionItemType.MISSING_CHECK_OUT],
    board: ['shift'],
  },
  UNAPPROVED_CHECK_OUT: {
    label: 'Unapproved Check-Out',
    types: [ActionItemType.UNAPPROVED_CHECK_OUT],
    board: ['shift'],
  },
  MISSING_T2: {
    label: 'Missing T2',
    types: [ActionItemType.MISSING_T2],
    board: ['shift'],
  },
  MISSING_T24: {
    label: 'Missing T24',
    types: [ActionItemType.MISSING_T24],
    board: ['shift'],
  },
  OVERBOOKED: {
    label: 'Overbooked',
    types: [ActionItemType.OVERBOOKED],
    board: ['shift'],
  },
  UNFILLED: {
    label: 'Unfilled',
    types: [ActionItemType.UNFILLED],
    board: ['shift'],
  },
  UNFILLED_PLAYBOOK_RUNNING: {
    label: 'Playbook Running',
    types: [ActionItemType.UNFILLED_PLAYBOOK_RUNNING],
    board: ['shift'],
  },
  FLAG_MISSED_CONFIRMATIONS: {
    label: 'Flag: Missing Confirmations',
    types: [ActionItemType.FLAG_MISSED_CONFIRMATIONS],
    board: ['worker'],
  },
  FLAG_NEGATIVE_REVIEW: {
    label: 'Flag: Negative Review',
    types: [ActionItemType.FLAG_NEGATIVE_REVIEW],
    board: ['worker'],
  },
  FLAG_OTHER: {
    label: 'Flag: Other',
    types: [ActionItemType.FLAG_OTHER],
    board: ['worker'],
  },
  FLAG_OUTDATED_PAPERWORK: {
    label: 'Flag: Paperwork',
    types: [ActionItemType.FLAG_OUTDATED_PAPERWORK],
    board: ['worker'],
  },
  FLAG_PROFILE_PHOTO: {
    label: 'Flag: Photo',
    types: [ActionItemType.FLAG_PROFILE_PHOTO],
    board: ['worker'],
  },
  FLAG_TERMS_VIOLATION: {
    label: 'Flag: Terms Violation',
    types: [ActionItemType.FLAG_TERMS_VIOLATION],
    board: ['worker'],
  },
  POST_SHIFT_BACKGROUND_CHECK: {
    label: 'Background Check',
    types: [ActionItemType.POST_SHIFT_BACKGROUND_CHECK],
    board: ['worker'],
  },
  POST_SHIFT_MISSING_EVEREE_INTEGRATION: {
    label: 'Missing Everee',
    types: [ActionItemType.POST_SHIFT_MISSING_EVEREE_INTEGRATION],
    board: ['worker'],
  },
  MISSING_I9: {
    label: 'Missing I9',
    types: [
      ActionItemType.MISSING_I9_ACCEPTED,
      ActionItemType.MISSING_I9_OVERBOOKED,
      ActionItemType.POST_SHIFT_MISSING_I9,
    ],
    board: ['worker'],
  },
  POST_SHIFT_PAPERWORK: {
    label: 'Worker Agreement',
    types: [ActionItemType.POST_SHIFT_PAPERWORK],
    board: ['worker', 'shift'],
  },
  PRE_SHIFT_PAPERWORK: {
    label: 'Worker Agreement',
    types: [ActionItemType.PRE_SHIFT_PAPERWORK],
    board: ['shift'],
  },
};

export const getActionItemsAsOptions = (
  board: 'shift' | 'worker',
): SelectOption[] => {
  return Object.entries(ActionItemTree)
    .filter(([_, item]) => item.board.includes(board))
    .map(([key, item]) => ({
      label: item.label,
      value: key,
    }));
};

export const getActionItemTypesByBoard = (
  board: 'shift' | 'worker',
): ActionItemType[] => {
  return Array.from(
    new Set(
      Object.entries(ActionItemTree)
        .filter(([_, item]) => item.board.includes(board))
        .flatMap(([_, item]) => item.types),
    ),
  );
};

export const getActionItemLabel = (type: ActionItemType): string => {
  return Object.entries(ActionItemTree).filter(([_, item]) =>
    item.types.includes(type),
  )[0][1].label;
};

export const getActionItemGraphTypes = (
  types: (keyof typeof ActionItemTree)[],
): ActionItemType[] => {
  return Array.from(new Set(types.flatMap(type => ActionItemTree[type].types)));
};
